import React from 'react'
import {useSelector} from 'react-redux';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import bannerImg from '@/assets/img/recipe/banner.jpg';
import productImg from '@/assets/img/recipe/product.png';

export default function Recipe() {
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'recipe-mobile-container' : 'recipe-container'

  const productList = [
    {},
    {},
    {},
    {},
    {},
    {},
  ]
  const renderProductListItem = (item, index) => <div
    className={'recipe-content-item-container'}
    key={`product_list_item_${index}`}>
    <div className={'recipe-content-item-img-container'}>
      <img src={productImg} alt=""/>
    </div>
    <div className={'recipe-content-item-info-container'}>
      <div className={'recipe-content-item-info-title'}>Scallop Garlic W/Vermicelli</div>
      <div className={'recipe-content-item-info-des'}>
        Indulge in a delightful seafood feast with our Scallop Garlic with Vermicelli recipe. This dish combines succulent scallops, aromatic garlic, and tender vermicelli noodles for a flavorful and satisfying meal.
      </div>
    </div>
  </div>
  const renderProductList = () => <div className={'recipe-content-container'}>
    <div className={'recipe-content-list'}>
      {productList.map((item, index) => renderProductListItem(item, index))}
    </div>
    <div className={'recipe-content-btn'}>Click to show more</div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'recipe-header-container'}
          des={'Recipes for you'}
          title={'From the Depths to the Plate: Dive into the Oceanic Delights of our Recipe Collection'}
          banner={bannerImg}
        />
        {renderProductList()}
      </div>
    </Layout>
  )
}
