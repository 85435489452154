import axios from 'axios'
import {message} from 'antd'

import {
  BASE_URL, 
  CONTENT_TYPE,
  JSON_POST,
  START_LOADING_EVENT_NAME,
  STOP_LOADING_EVENT_NAME,
} from '@/constants'
import eventBus from '@/common/EventBus';
import {getToken} from '@/common';

const instance = axios.create({
    baseURL: BASE_URL,
})
instance.defaults.headers.post[CONTENT_TYPE] = JSON_POST;
instance.defaults.withCredentials = false; // 携带cookie
instance.defaults.timeout = 1000*60*5; // 默认请求超时毫秒

//请求拦截器
instance.interceptors.request
  .use(config => {
      const token = getToken()
      eventBus.dispatchEvent(START_LOADING_EVENT_NAME)
      if (token) {
        config.headers.Authorization = `JWT ${token}`
      }
      return config
    }, 
      error => {
        eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
        Promise.reject(error)
      }
);
// 响应拦截器
instance.interceptors.response
  .use(response => {
      eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
      return response
    },
      error => {
        eventBus.dispatchEvent(STOP_LOADING_EVENT_NAME)
        const errData = error.response?.data || {}
        const {non_field_errors, message: resMessage} = errData
        if (non_field_errors && Array.isArray(non_field_errors)) {
          message.error(non_field_errors[0])
        }
        if (resMessage) {
          message.error(resMessage)
        }
        return Promise.reject(errData)
      }
);

export default instance
