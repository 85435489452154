import {trim, isFunction} from 'lodash'

import {DEFAULT_LOCATION, LOCATION_LIST, REDIRECT_KEY, TOKEN_KEY, USER_ZIPCODE_KEY} from '@/constants';

/**
 * 或是是否是手机上显示（手机网页、手机APP）
 * @returns {boolean}
 */
export function getIsMobile () {
  const userAgent = navigator.userAgent
  return /mobile/i.test(userAgent)
}

/**
 * 
 * @param classNameList
 * @returns {*}
 */
export function getValidClassNameList (classNameList) {
  return classNameList.map(item => trim(item, ' ')).filter(item => item)
}
/**
 * 获取className
 * @param defaultClassNameList
 * @param addClassNameList
 * @param isAdd
 * @returns {string}
 */
export function getUsingClassName (defaultClassNameList = [], addClassNameList = [], isAdd = true) {
  const validDefaultClassNameList = getValidClassNameList(defaultClassNameList);
  const validAddClassNameList = getValidClassNameList(addClassNameList);
  const usingClassNameList = isAdd ? validDefaultClassNameList.concat(validAddClassNameList) : validDefaultClassNameList
  return usingClassNameList.join(' ')
}

export function checkViewableLanguages(data, lang) {
  for (let i = 0; i < data.split(',').length; i++) {
    let d = data.split(',')[i];
    if (d.toLowerCase()==='zh') { d = 'ch'}
    if (d.toLowerCase() === lang) {
      return true;
    }
  }
  return false;
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token || '')
}
export function removeToken() {
  localStorage.removeItem(TOKEN_KEY)
}
export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || ''
}

export function setUserZipcode(zipcode) {
  localStorage.setItem(USER_ZIPCODE_KEY, zipcode || '')
}
export function removeUserZipcode() {
  return localStorage.removeItem(USER_ZIPCODE_KEY)
}
export function getUserZipcode() {
  return localStorage.getItem(USER_ZIPCODE_KEY)
}
export function getLocationInfo() {
  const userZipcode = getUserZipcode()
  const resItem = LOCATION_LIST.find(item => item.zipcode === userZipcode) || DEFAULT_LOCATION
  return {
    Company_id_id: resItem.companyId,
    warehouse_id: resItem.pickupWarehouseIdId
  }
}

export function getEncodeStr(str) {
  return encodeURIComponent(str)
}
export function getDecodeStr(str) {
  return decodeURIComponent(str)
}

export function beforeAddToCart(navigate, isLogged, location, callback) {
  if (!isLogged) {
    const searchParams = new URLSearchParams({[REDIRECT_KEY]: getEncodeStr(location.pathname)})
    return navigate(`/signupLogin?${searchParams}`)
  }
  isFunction(callback) && callback()
}

export function getFormatMoney(value) {
  if (!value && value !== 0) {
    return ''
  }
  return value
  // const [num1, num2] = Number(value).toFixed(2).split('.')
  // return `${Number(num1).toLocaleString()}.${num2}`
}
