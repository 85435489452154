import React, {useState, useRef} from 'react'
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Modal, Input, Form, Upload, Button} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductItem from '@/components/ProductItem'
import bannerImg from '@/assets/img/careerDetail/banner.jpg';
import productImg from '@/assets/img/career/product.png';

export default function CareerDetail() {
  const navigate = useNavigate()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'career-detail-mobile-container' : 'career-detail-container'

  const formRef = useRef(null);
  
  const [isShowApplyNow, setIsShowApplyNow] = useState(false)
  
  const productList = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  const [fileList, setFileList] = useState([]);
  const handleResumeChange = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };
  
  const showApplyNowClick = () => {
    setIsShowApplyNow(true)
  }
  const hideApplyNow = () => {
    setIsShowApplyNow(false)
  }
  const onFormFinish = (values) => {
    console.log(values);
  }
  const resetFields = () => formRef.current?.resetFields()
  const handleApplyNowCancel = () => {
    hideApplyNow()
    resetFields()
  }
  const handleApplyNowClick = () => {
    formRef.current?.submit()
  }
  
  const handleTermsOfServiceClick = () => {
    navigate('/termsOfService')
  }
  const handlePrivacyPolicyClick = () => {
    navigate('/privacyPolicy')
  }
  
  const renderNewDetailContent = () => <div className={'career-detail-content-container'}>
    <div className={'career-detail-content-job-description'}>
      <span className={'career-detail-content-title'}>Job Description</span>
      <span className={'career-detail-content-des'}>: Software Engineer</span>
    </div>
    <div className={'career-detail-content-company-overview'}>
      <span className={'career-detail-content-title'}>Company Overview</span>
      <span className={'career-detail-content-des'}>: At [Company Name], we are a leading innovator in [industry/technology]. We are passionate about leveraging cutting-edge technology to shape the future and drive transformative solutions. As we continue to expand our operations, we are seeking a talented and driven Software Engineer to join our innovative tech team.</span>
    </div>
    <div className={'career-detail-content-des'}>
      Role Overview: As a Software Engineer at [Company Name], you will play a pivotal role in developing and maintaining high-quality software applications that will drive our success in the market. You will collaborate with cross-functional teams, including product managers, designers, and fellow engineers, to create scalable and robust software solutions.
    </div>
    <div className={'career-detail-content-responsibilities'}>
      <div className={'career-detail-content-title'}>Responsibilities:</div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;1. Design, develop, and implement software applications that meet business requirements and align with industry best practices.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;2. Collaborate with product managers and stakeholders to understand project goals and deliver software solutions that address user needs.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;3. Write clean, efficient, and maintainable code using modern programming languages and frameworks.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;4. Conduct thorough testing and debugging to ensure the quality and reliability of software applications.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;5. Collaborate with cross-functional teams to gather and analyze user feedback, making necessary improvements and enhancements to the software.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;6. Keep up-to-date with emerging technologies, industry trends, and best practices to continuously improve software development processes and techniques.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;7. Participate in code reviews, providing constructive feedback and ensuring adherence to coding standards.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;8. Troubleshoot and resolve software defects and performance issues, identifying root causes and implementing effective solutions.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;9. Contribute to the documentation of software design, architecture, and technical specifications.
      </div>
      <div className={'career-detail-content-des'}>
        10. Collaborate with team members to foster a positive and collaborative work environment, promoting knowledge sharing and continuous learning.
      </div>
    </div>
    <div className={'career-detail-content-requirements'}>
      <div className={'career-detail-content-title'}>Requirements:</div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;1. Bachelor's degree in Computer Science, Software Engineering, or a related field. Master's degree is a plus.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;2. Proven experience as a Software Engineer, with a strong track record of developing and delivering high-quality software applications.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;3. Proficiency in programming languages such as Java, C++, Python, or JavaScript.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;4. Experience with modern software development frameworks and tools.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;5. Solid understanding of software development methodologies, coding standards, and best practices.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;6. Strong problem-solving skills and ability to quickly grasp and apply new concepts and technologies.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;7. Excellent communication and teamwork abilities, with a demonstrated aptitude for collaborating effectively with cross-functional teams.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;8. Attention to detail and a commitment to delivering software solutions of the highest quality.
      </div>
      <div className={'career-detail-content-des'}>
        &nbsp;&nbsp;9. Familiarity with Agile/Scrum methodologies is a plus.
      </div>
      <div className={'career-detail-content-des'}>
        10. A passion for innovation, staying up-to-date with industry trends, and a desire to shape the future through technology.
      </div>
    </div>
    <div className={'career-detail-content-des'}>
      Join us at [Company Name] and be part of a dynamic and forward-thinking team that is pushing the boundaries of technology. Together, we will shape the future and make a meaningful impact in [industry/technology]. Apply now and embark on an exciting journey with us!
    </div>
  </div>
  
  const renderProductListItem = (item, index) => <div
    className={'career-detail-related-blogs-item-container'}
    key={`product_list_item_${index}`}>
    <ProductItem
      img={productImg}
      price={''}
      des={'From Sea to Plate: Exploring the World of Fresh Seafood'}
      btnText={'Read More'}
    />
  </div>
  const renderProductList = () => <div className={'career-detail-related-blogs-container'}>
    <div className={'career-detail-related-blogs-title'}>Related posts</div>
    <div className={'career-detail-related-blogs-list'}>
      {productList.map((item, index) => renderProductListItem(item, index))}
    </div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'career-detail-header-container'}
          des={'Career'}
          title={'Software Engineer Needed: Join Our Innovative Tech Team and Shape the Future'}
          banner={bannerImg}
          btnText={'Apply Now'}
          onBtnClick={showApplyNowClick}
        />
        {renderNewDetailContent()}
        {renderProductList()}
        <Modal
          footer={null}
          maskClosable={false}
          open={isShowApplyNow}
          onCancel={handleApplyNowCancel}
          className={'career-detail-apply-now-modal'}>
          <div className={'career-detail-apply-now-title'}>Unlock Your Potential: Apply Today and Join Our Team of Innovators</div>
          <Form
            className={'career-detail-apply-now-form-container'}
            ref={formRef}
            layout={'vertical'}
            requiredMark={false}
            onFinish={onFormFinish}
            autoComplete="off">
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                {
                  required: true,
                  message: 'Please enter your full name',
                },
              ]}>
              <Input
                className={'career-detail-apply-now-form-input'}
                placeholder={'Enter your full name'}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="emailAddress"
              rules={[
                {
                  required: true,
                  message: 'Please enter your email address',
                },
              ]}>
              <Input
                className={'career-detail-apply-now-form-input'}
                placeholder={'Enter your email address'}
              />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phoneNumber">
              <Input
                className={'career-detail-apply-now-form-input'}
                placeholder={'Enter your phone number'}
              />
            </Form.Item>
            <Form.Item
              label="Describe yourself"
              name="describeYourself">
              <Input.TextArea
                className={'career-detail-apply-now-form-textarea'}
                placeholder={'Tell us more about your self'}
              />
            </Form.Item>
            <Form.Item
              label="Resume"
              name="resume">
              <Upload
                name="resume"
                action="/upload.do"
                onChange={handleResumeChange}
                listType="picture"
                fileList={fileList}>
                <Button icon={<UploadOutlined />}>
                  Upload Files ({fileList.length}/1)
                </Button>
              </Upload>
            </Form.Item>
          </Form>
          <div className={'career-detail-apply-now-tip-container'}>
            <span>By continuing, you agree to Galaxy Global International’s </span>
            <span className={'career-detail-apply-now-tip-link'} onClick={handleTermsOfServiceClick}>Terms of Service</span>
            <span> and </span>
            <span className={'career-detail-apply-now-tip-link'} onClick={handlePrivacyPolicyClick}>Privacy Policy</span>
          </div>
          <div className={'career-detail-apply-now-btn'} onClick={handleApplyNowClick}>Apply Now</div>
        </Modal>
      </div>
    </Layout>
  )
}
