import {startLoading} from "@/store/modules/common.slice";

export const HTTP_POST = 'post';
export const HTTP_GET = 'get';
export const HTTP_PUT = 'put';
export const HTTP_DELETE = 'delete';
export const SIMPLE_POST = 'application/x-www-form-urlencoded;charset=UTF-8';
export const JSON_POST = 'application/json;charset=UTF-8';
export const FORM_DATA = 'multipart/form-data';
export const CONTENT_TYPE = "Content-Type";

export const BASE_URL= 'https://ggi-scm.truckxi.com';
export const EN_LANGUAGE_KEY= 'en';
export const ZH_LANGUAGE_KEY= 'zh';
export const TOKEN_KEY= 'token';
export const BILLING_ADDRESS_KEY= 'Billing';
export const USER_ZIPCODE_KEY= 'zipcode';
export const REDIRECT_KEY= 'redirect';
export const START_LOADING_EVENT_NAME = 'startLoadingEvent'
export const STOP_LOADING_EVENT_NAME = 'stopLoadingEvent'
export const LOCATION_LIST = [
  {
    name: 'LA',
    zipcode: '91754',
    companyId: '1',
    pickupWarehouseIdId: '64'
  },
  {
    name: 'GA',
    zipcode: '30029',
    companyId: '4',
    pickupWarehouseIdId: '63'
  },
]
export const DEFAULT_LOCATION = LOCATION_LIST[0]
export const DEFAULT_ZIPCODE = DEFAULT_LOCATION.zipcode
