import React from 'react'
import {useSelector} from 'react-redux';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import RegisterAccount from '@/components/RegisterAccount'
import bannerImg from '@/assets/img/contactUs/banner.jpg';
import wechatImg from '@/assets/img/icon/wechat.png';
import instagramImg from '@/assets/img/icon/instagram.png';
import facebookImg from '@/assets/img/icon/facebook.png';
import mapImg from '@/assets/img/contactUs/map.png';

export default function ContactUs() {
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'contact-us-mobile-container' : 'contact-us-container'
  
  return (
    <Layout isShowFooterTop={false}>
      <div className={rootClassName}>
        <ContentHeader
          className={'contact-us-header-container'}
          des={'Contact us'}
          title={'Get in Touch with Us: Contact Our Friendly Team Today!'}
          banner={bannerImg}
        />
        <div className={'contact-us-content-container'}>
          <RegisterAccount
            className={'contact-us-content-left-container'}
            title={'Get in touch'}
          />
          <div className={'contact-us-content-right-container'}>
            <div className={'contact-us-content-right-working-hours-container'}>
              <div className={'contact-us-content-right-title'}>WORKING HOURS</div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                Monday - Saturday
                <span>: 9am - 6pm</span>
              </div>
              <div className={'contact-us-content-right-working-hours-item-container'}>
                Sunday
                <span>: 1pm - 6pm</span>
              </div>
            </div>
            <div className={'contact-us-content-right-contact-us-container'}>
              <div className={'contact-us-content-right-title'}>CONTACT Us</div>
              {
                [1, 2, 3].map((item, index) => <div key={`contact_us_item_${index}`}>
                  <div className={'contact-us-content-right-contact-us-title'}>
                    Galaxy Global International
                  </div>
                  <div className={'contact-us-content-right-contact-us-address'}>
                    4300 Pleasantdale Rd b, Suite B. Atlanta, GA 30340
                  </div>
                  <div className={'contact-us-content-right-contact-us-des'}>
                    Phone: (770)-676-9288
                  </div>
                  <div className={'contact-us-content-right-contact-us-des'}>
                    Fax: (770)-695-0145
                  </div>
                  <div className={'contact-us-content-right-contact-us-des'}>
                    Sales Direct line: (470)-535-7677
                  </div>
                  <div className={'contact-us-content-right-contact-us-des'}>
                    Email:&nbsp;
                    <span>
                  op@ggifoods.com
                </span>
                  </div>
                </div>)
              }
            </div>
            <div className={'contact-us-content-right-social-medias-container'}>
              <div className={'contact-us-content-right-title'}>social medias</div>
              <div className={'contact-us-content-right-social-medias-list'}>
                <div className={'contact-us-content-right-social-medias-item-container'}>
                  <img src={wechatImg} alt="wechat" />
                </div>
                <div className={'contact-us-content-right-social-medias-item-container'}>
                  <img src={instagramImg} alt="instagram" />
                </div>
                <div className={'contact-us-content-right-social-medias-item-container'}>
                  <img src={facebookImg} alt="facebook" />
                </div>
              </div>
            </div>
            <div className={'contact-us-content-right-map-container'}>
              <img src={mapImg} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
