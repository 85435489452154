import {Navigate, createBrowserRouter} from 'react-router-dom';

import Home from '@/pages/Home'
import OurProducts from '@/pages/OurProducts'
import ProductDetail from '@/pages/ProductDetail'
import Recipe from '@/pages/Recipe'
import News from '@/pages/News'
import NewDetail from '@/pages/NewDetail'
import Career from '@/pages/Career'
import CareerDetail from '@/pages/CareerDetail'
import ContactUs from '@/pages/ContactUs'
import SignupLogin from '@/pages/SignupLogin'
import ShoppingCart from '@/pages/ShoppingCart'

const router = createBrowserRouter([
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/ourProducts',
    element: <OurProducts />,
  },
  {
    path: '/ourProducts/:group',
    element: <OurProducts />,
  },
  {
    path: '/ourProducts/:group/:category',
    element: <OurProducts />,
  },
  {
    path: '/productDetail/:id',
    element: <ProductDetail />,
  },
  {
    path: '/recipe',
    element: <Recipe />,
  },
  {
    path: '/news',
    element: <News />,
  },
  {
    path: '/newDetail',
    element: <NewDetail />,
  },
  {
    path: '/career',
    element: <Career />,
  },
  {
    path: '/careerDetail',
    element: <CareerDetail />,
  },
  {
    path: '/contactUs',
    element: <ContactUs />,
  },
  {
    path: '/signupLogin',
    element: <SignupLogin />,
  },
  {
    path: '/shoppingCart',
    element: <ShoppingCart />,
  },
  {
    path: '*',
    element: <Navigate to={'/home'} replace={true} />
  },
]);

export default router
