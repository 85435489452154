import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Spin} from 'antd';

import './index.less'

import router from '@/router'

import {getToken} from '@/common';
import eventBus from '@/common/EventBus'
import {fetchProductGroup, fetchUserInfo, setIsLogged, startLoading, stopLoading} from '@/store/modules/common.slice';
import {START_LOADING_EVENT_NAME, STOP_LOADING_EVENT_NAME} from '@/constants';


function App() {
  const dispatch = useDispatch()

  const commonState = useSelector(state => state.common)
  const loading = commonState.loading
  
  const isLogged = !!getToken()
  const startLoadingEventFun = () => {
    if (loading) {
      return
    }
    dispatch(startLoading())
  }
  const stopLoadingEventFun = () => {
    dispatch(stopLoading())
  }
  useEffect(() => {
    dispatch(fetchProductGroup())
    dispatch(setIsLogged(isLogged))
    if (isLogged) {
      dispatch(fetchUserInfo())
    }
    
    eventBus.addEventListener(START_LOADING_EVENT_NAME, startLoadingEventFun)
    eventBus.addEventListener(STOP_LOADING_EVENT_NAME, stopLoadingEventFun)
    
    return () => {
      eventBus.removeEventListener(START_LOADING_EVENT_NAME, startLoadingEventFun)
      eventBus.removeEventListener(STOP_LOADING_EVENT_NAME, stopLoadingEventFun)
    }
  }, [])
  
  const renderRoute = (item, index) => <Route {...item} key={'route_' + index} />
  const renderRoutes = () => <Routes>
    {router.routes.map((item, index) => renderRoute(item, index))}
  </Routes>
  
  return (
    <BrowserRouter>
      <div className="app">
        {renderRoutes()}
      </div>
      {
        loading && <Spin
          rootClassName="spin-root-class"
          size={'large'}
        />
      }
    </BrowserRouter>
  );
}

export default App;
