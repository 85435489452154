import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Input, Menu, Dropdown, message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductItem from '@/components/ProductItem'

import bannerImg from '@/assets/img/ourProducts/banner.jpg'
import storeIcon from '@/assets/img/icon/store.png'
import searchIcon from '@/assets/img/icon/search.png'
import {addToCart, getCategoryProducts, getGroupProducts} from "@/api/req-api";
import Loading from "@/components/Loading";
import {beforeAddToCart} from "@/common";

export default function OurProducts() {
  const {group, category} = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [products, setProducts] = useState([])
  const commonState = useSelector(state => state.common)
  const {productGroupMenu, productCategoryMap, productGroupMap, isLogged} = commonState
  const rootClassName = commonState.isMobile ? 'our-products-mobile-container' : 'our-products-container'
  const productGroupMapKeys = Object.keys(productGroupMap)
  const productCategoryMapKeys = Object.keys(productCategoryMap)
  const [selectedKeys, setSelectedKeys] = useState('')
  const [openKeys, setOpenKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const [groupDetail, setGroupDetail] = useState({})
  const [categoryDetail, setCategoryDetail] = useState({})
  const filterRef = useRef()
  const searchInputRef = useRef()
  const originalData = useRef()
  const touchBottom = useRef()
  const breadCrumbRef = useRef()
  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll)
    let observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true) {
        console.log('Element has just become visible in screen');
        filterRef.current.classList.remove('fixed')
        breadCrumbRef.current.classList.remove('fixed')
        touchBottom.current = true
      }
      if(entries[0].isIntersecting === false) {
        console.log('Element has just become visible out screen');
        if(touchBottom.current === true) {
          filterRef.current.classList.add('fixed')
          breadCrumbRef.current.classList.add('fixed')
        }
        touchBottom.current = false
      }
    }, { threshold: [0] });

    observer.observe(document.querySelector("#page-footer"));
    return () => {
      window.removeEventListener('scroll', handlePageScroll)
      observer.unobserve(document.querySelector("#page-footer"))
      observer.disconnect()
    }
  }, [])
  useEffect(() => {
    if (productGroupMapKeys.length > 0) {
      if (!group) {
        const firstKey = Object.keys(productGroupMap)[0]
        const firstGroup = productGroupMap[firstKey]
        navigate(`/ourProducts/${firstGroup.Name.toLowerCase()}`, {replace: true})
      } else {
        const groupId = productGroupMapKeys.find(key => productGroupMap[key].Name.toLowerCase() === decodeURIComponent(group))
        if (groupId) {
          const groupKey = productGroupMap[groupId].Name
          setGroupDetail(productGroupMap[groupId])
          setOpenKeys([groupKey])
          if (category) {
            const categoryId = productCategoryMapKeys.find(key => productCategoryMap[key].Name.toLowerCase() === decodeURIComponent(category))
            if (categoryId) {
              fetchCategoryProducts(categoryId)
              setCategoryDetail(productCategoryMap[categoryId])
              setSelectedKeys(categoryDetail.Name)
            }
          } else {
            fetchGroupProducts(groupId)
            setCategoryDetail({})
          }
        } else {
          //todo raise an error
        }
      }
    }
  }, [group, category, productCategoryMap])
  
  const fetchGroupProducts = groupId => {
    setLoading(true)
    getGroupProducts(groupId).then(response => {
      setProducts(response.data)
      setLoading(false)
      originalData.current = response.data
    })
  }
  
  const fetchCategoryProducts = categoryId => {
    setLoading(true)
    getCategoryProducts(categoryId).then(response => {
      setProducts(response.data)
      setLoading(false)
      originalData.current = response.data
    })
  }
  const handleMenuClick = event => {
    const categoryKey = event.key.toLowerCase()
    navigate(`/ourProducts/${groupDetail.Name.toLowerCase()}/${categoryKey}`, {replace: true})
  };

  const handleMenuOpen = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey])
      navigate(`/ourProducts/${latestOpenKey.toLowerCase()}`, {replace: true})
    } else {
      setOpenKeys([])
    }
  }
  
  const sortByMenuList = [
    {
      key: 'Group',
      label: 'Group',
    },
    {
      key: 'Category',
      label: 'Category',
    },
    {
      key: 'Name',
      label: 'Name',
    },
    {
      key: 'Price',
      label: 'Price',
    },
  ]
  
  const handleProductItemClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    if (location.pathname === path) {
      return
    }
    navigate(path);
  }
  
  const handleAddToCart = item => {
    addToCart(item).then(() => {
      message.success('Added successfully')
    })
  }

  const handleBeforeAddToCart = item => {
    beforeAddToCart(
      navigate,
      isLogged,
      location,
      () => handleAddToCart(item)
    )
  }
  
  const handleSearch = () => {
    const keywords = searchInputRef.current.input.value
    if (keywords) {
      setProducts([...originalData.current.filter(product => 
          product.Name.toLowerCase().includes(keywords.toLowerCase() 
            || product.RefNum.includes(keywords.toLowerCase()))
      )])
    } else {
      setProducts([...originalData.current])
    }
  }
  
  const renderRightSearchIcon = () => <div className={'our-products-content-right-search-icon-container'}>
    <img src={searchIcon} alt="" onClick={handleSearch}/>
  </div>

  const renderProductGroupItem = (item, index) => <div
    className={'our-products-content-right-product-group-item-container'}
    key={`product_group_item_${index}`}>
    <ProductItem 
        img={item.Thumbnail} 
        price={item.Price} 
        des={item.Name} 
        onProductItemClick={() => handleProductItemClick(item)}
        productType={item.ProductType}
        isShowPrice={isLogged}
        onBtnClick={() => handleBeforeAddToCart(item)}
        isFormatPriceToMoney={true}
    />
  </div>
  const renderProductGroup = () => <div className={'our-products-content-right-product-group-container'}>
    <div className={'our-products-content-right-product-group-list'}>
      {products.map((item, index) => renderProductGroupItem(item, index))}
    </div>
    <div className={'our-products-content-right-product-group-btn'}>Click to show more</div>
  </div>
  
  const handlePageScroll = event => {
    if(event.target.documentElement.scrollTop >= 950) {
      if (!touchBottom.current) {
        filterRef.current.classList.remove('stickBottom')
        filterRef.current.classList.add('fixed')
        breadCrumbRef.current.classList.remove('stickTop')
        breadCrumbRef.current.classList.add('fixed')
      } else {
        filterRef.current.classList.add('stickBottom')
        breadCrumbRef.current.classList.add('stickTop')
      }
    } else {
      filterRef.current.classList.remove('fixed')
      breadCrumbRef.current.classList.remove('fixed')
    }
  }
  return (
    <Layout>
      <div className={rootClassName} onScroll={handlePageScroll}>
        <ContentHeader
          className={'our-products-header-container'}
          des={'Product listing'}
          title={'Discover Our Exceptional Product Collection: Elevate Your Lifestyle Today!'}
          banner={bannerImg}
        />
        <div className={'our-products-content-container'}>
          <div className={'our-products-content-filter-container'} ref={filterRef}>
            <div className={'our-products-content-filter-top-container'}>
              <div className={'our-products-content-filter-top-title'}>Filter</div>
              <div className={'our-products-content-filter-top-clear-filter'}>Clear filter</div>
            </div>
            <Menu
              className={'our-products-content-filter-menu-container'}
              onClick={handleMenuClick}
              onOpenChange={handleMenuOpen}
              activeKey={selectedKeys}
              // defaultSelectedKeys={defaultSelectedKeys}
              openKeys={openKeys}
              mode="inline"
              items={productGroupMenu}
            />
          </div>
          <div className={'our-products-content-right-container'}>
            <div className={'our-products-content-right-title-container'} ref={breadCrumbRef}>
              <div className={'our-products-content-right-title-icon-container'}>
                <img src={storeIcon} alt=""/>
              </div>
              <div>All Product{groupDetail.Name ? ` > ${groupDetail.Name}` : ''}{categoryDetail.Name ? ` > ${categoryDetail.Name}` : ''}</div>
            </div>
            <div className={'our-products-content-right-search-sort-container'}>
              <Input
                ref={searchInputRef}  
                className={'our-products-content-right-search-container'}
                suffix={renderRightSearchIcon()}
                placeholder={'Search by Product Name'}
                onPressEnter={handleSearch}
              />
              <Dropdown
                className={'our-products-content-right-sort-container'}
                menu={{
                  items: sortByMenuList,
                }}>
                <div>Sort By</div>
              </Dropdown>
            </div>
            {loading ? <Loading text={'Loading Products'} /> : renderProductGroup()}
          </div>
        </div>
      </div>
    </Layout>
  )
}
