import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux';
import {debounce} from 'lodash'
import {useNavigate} from 'react-router-dom';

import {Form, Input, Radio, Space, InputNumber, message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import visaIcon from '@/assets/img/icon/visa.png'
import masterCardIcon from '@/assets/img/icon/masterCard.png'
import americanExpressIcon from '@/assets/img/icon/americanExpress.png'
import wechatIcon from '@/assets/img/icon/wechat.png'

import {
  getCart,
  getOrderCart,
  deleteCartItem,
  updateItemQuantity,
  submitOrder
} from '@/api/req-api';
import {getFormatMoney} from '@/common';

const PICK_UP_KEY = 'Pickup'
const DELIVERY_KEY = 'Delivery'
const methodList = [
  {
    label: 'Pick Up',
    value: PICK_UP_KEY,
  },
  {
    label: 'Delivery',
    value: DELIVERY_KEY,
  }
]
const paymentTypeList = [
  {
    label: visaIcon,
    value: 'creditcard',
  },
  {
    label: masterCardIcon,
    value: '2',
  },
  {
    label: americanExpressIcon,
    value: '3',
  },
  {
    label: wechatIcon,
    value: '4',
  },
]

export default function ShoppingCart() {
  const navigate = useNavigate()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'shopping-cart-mobile-container' : 'shopping-cart-container'
  const customerDetail = commonState.customerDetail
  const isBypassPayment = commonState.userInfo?.BypassPayment
 
  const [method, setMethod] = useState(methodList[0].value);
  const onMethodChange = event => {
    setMethod(event.target.value);
  };
  const isDelivery = useMemo(
    () => method === DELIVERY_KEY, 
    [method]
  )
  const isShowPaymentInformation = !isBypassPayment && isDelivery
  const [addressList, setAddressList] = useState([])
  useEffect(() => {
    const addressList = customerDetail.map((item) => {
      return {
        label: `${item.Street}, ${item.City}, ${item.Zip}`,
        value: item.CustomerDetail_id,
      }
    })
    setAddressList(addressList)
  }, [customerDetail])
  const [address, setAddress] = useState('');
  useEffect(() => {
    setAddress(addressList[0]?.value)
  }, [addressList])
  const onAddressChange = event => {
    setAddress(event.target.value);
  };
  const [paymentType, setPaymentType] = useState(paymentTypeList[0].value);
  const onPaymentTypeChange = event => {
    setPaymentType(event.target.value);
  };
  
  const [orderCartInfo, setOrderCartInfo] = useState(null)
  const [cartInfo, setCartInfo] = useState(null)
  const getCartByApi = () => {
    const params = {
      PromoCode: null,
      SalesOrder_id: orderCartInfo.SalesOrder_id,
      Sales_Order_Lines: orderCartInfo.Sales_Order_Lines,
      ShippingMethod: method,
      Tip: 0,
      TipPercentage: 0,
      TotalPrice: orderCartInfo.TotalPrice,
    }
    getCart(params).then(res => {
      setCartInfo(res.data)
    })
  }
  const getOrderCartByApi = () => {
    getOrderCart().then(res => {
      setOrderCartInfo(res.data)
    })
  }
  useEffect(() => {
    getOrderCartByApi()
  }, [])
  useEffect(() => {
    orderCartInfo && getCartByApi()
  }, [orderCartInfo, method])
  
  const handleRemoveItemClick = item => {
    deleteCartItem(item)
      .then(() => {
        message.success('Removed successfully')
        getOrderCartByApi()
      })
  }
  
  const handleQuantityChange = debounce((value, item) => {
    updateItemQuantity(item, value).then(() => {
      getOrderCartByApi()
    })
  }, 300)

  const formRef = useRef(null);
  const initialValues = {
    paymentType: undefined,
    cardholderName: 'Name',
    cardNumber: ('4111 1111 1111 1111').replaceAll(' ', ''),
    expirationDate: '08/24',
    cvv: '1234'
  }
  const resetFields = () => formRef.current?.resetFields()
  const onFormFinish = (values) => {
    const params = {
      Deliver_CustomerDetail_id: isDelivery ? address : null,
      Billing_CustomerDetail_id: address,
      warehouse_zipcode: '98007',
      Sales_Order_Lines: orderCartInfo.Sales_Order_Lines,
      FinalPrice: cartInfo.TotalPrice,
      TotalPrice: cartInfo.TotalPrice,
      GrandTotal: cartInfo.GrandTotal,
      SalesOrder_id: orderCartInfo.SalesOrder_id,
      ShippingMethod: method,
      PaymentMethod: isBypassPayment ? 'bypass' : paymentType,
      terminal: 'ONLINE',
      PromoCode: null,
      Savings: cartInfo.Savings,
      DispatchDate: '2023-08-02 14:00:00',
      TimeSlot: '2023-08-02 14:00:00',
      Note: undefined,
      Tip: cartInfo.Tip,
      TipPercentage: cartInfo.TipPercentage,
    }
    if (isShowPaymentInformation) {
      params.card_reference = {
        cardNum: values.cardNumber,
        expDate: values.expirationDate,
        card_holder_name: values.cardholderName,
        cvv: values.cvv,
        salesOrderId: orderCartInfo.SalesOrder_id
      }
    }
    submitOrder(params).then(() => {
      message.success('Placed an order successfully')
        .then(() => {
          resetFields()
          navigate('/')
        })
    })
  }
  const handlePlaceAnOrderClick = () => {
    if (!orderCartInfo?.Sales_Order_Lines?.length) {
      return message.warning('Please add the product to the shopping cart first')
    }
    if (!isShowPaymentInformation) {
      onFormFinish()
    } else {
      formRef.current?.submit()
    }
  }
  
  const renderPaymentType = () => <div className={'shopping-cart-left-delivery-payment-type-container'}>
    <div className={'shopping-cart-left-delivery-payment-type-title'}>Payment Type:</div>
    <Radio.Group
      className={'shopping-cart-left-delivery-payment-type-group'}
      onChange={onPaymentTypeChange}
      value={paymentType}>
      {
        paymentTypeList.map((item, index) => <Radio
          className={'shopping-cart-left-delivery-payment-type-radio-container'}
          value={item.value}
          key={`payment_type_${index}`}>
          <img src={item.label} alt="" />
        </Radio>)
      }
    </Radio.Group>
  </div>
  
  const renderPaymentInformation = () => <div
    className={'shopping-cart-left-delivery-payment-container'}>
    <div className={'shopping-cart-left-title'}>Payment Information</div>
    <Form
      className={'shopping-cart-left-delivery-payment-form-container'}
      ref={formRef}
      layout={'vertical'}
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onFormFinish}
      autoComplete="off">
      <Form.Item
        name="paymentType">
        {renderPaymentType()}
      </Form.Item>
      <Form.Item
        label="Cardholder Name"
        name="cardholderName"
        rules={[
          {
            required: true,
            message: 'Please enter cardholder name',
          },
        ]}>
        <Input
          placeholder={'NAME ON CARD'}
        />
      </Form.Item>
      <Form.Item
        label="Card Number"
        name="cardNumber"
        rules={[
          {
            required: true,
            message: 'Please enter card number',
          },
        ]}>
        <Input
          placeholder={'ENTER CARD NUMBER'}
        />
      </Form.Item>
      <div className={'shopping-cart-left-delivery-payment-form-row'}>
        <Form.Item
          label="Expiration Date"
          name="expirationDate"
          rules={[
            {
              required: true,
              message: 'Please enter expiration date',
            },
          ]}>
          <Input
            placeholder={'MM/YY'}
          />
        </Form.Item>
        <Form.Item
          label="CVV"
          name="cvv"
          rules={[
            {
              required: true,
              message: 'Please enter cvv',
            },
          ]}>
          <Input
            placeholder={'ENTER CVV'}
          />
        </Form.Item>
      </div>
      <div className={'shopping-cart-left-delivery-payment-divider'}></div>
      <Form.Item
        label="Discount"
        name="discount">
        <div className={'shopping-cart-left-delivery-payment-discount-container'}>
          <Input
            placeholder={'Discount code'}
          />
          <div className={'shopping-cart-left-delivery-payment-form-btn'}>Apply</div>
        </div>
      </Form.Item>
    </Form>
  </div>
  
  return (
    <Layout isDetailPage={true} detailPageTitle={'Shopping Cart'}>
      <div className={rootClassName}>
        <div className={'shopping-cart-left-container'}>
          <div className={'shopping-cart-left-delivery-information-container'}>
            <div className={'shopping-cart-left-title'}>Delivery Information</div>
            <div className={'shopping-cart-left-delivery-information-method-container'}>
              <div className={'shopping-cart-left-delivery-information-method-title'}>Method</div>
              <Radio.Group onChange={onMethodChange} value={method}>
                {
                  methodList.map((item, index) => <Radio
                    value={item.value}
                    key={`method_${index}`}>
                    {item.label}
                  </Radio>)
                }
              </Radio.Group>
            </div>
            <div className={'shopping-cart-left-delivery-information-address-container'}>
              <div className={'shopping-cart-left-delivery-information-address-title'}>Address</div>
              <Radio.Group onChange={onAddressChange} value={address}>
                <Space direction="vertical">
                  {
                    addressList.map((item, index) => <Radio
                      value={item.value}
                      key={`method_${index}`}>
                      {item.label}
                    </Radio>)
                  }
                </Space>
              </Radio.Group>
            </div>
          </div>
          {isShowPaymentInformation && renderPaymentInformation()}
        </div>
        <div className={'shopping-cart-right-container'}>
          <div className={'shopping-cart-right-title'}>Order Summary</div>
          <div className={'shopping-cart-right-line'}></div>
          <div className={'shopping-cart-right-product-list-container'}>
            {
              orderCartInfo?.Sales_Order_Lines?.map((item, index) => <div
                className={'shopping-cart-right-product-list-item-container'}
                key={`product_list_${index}`}>
                <div className={'shopping-cart-right-product-list-item-left-container'}>
                  <div className={'shopping-cart-right-product-list-item-left-img-container'}>
                    <img src={item.Thumbnail} alt=""/>
                  </div>
                  <div className={'shopping-cart-right-product-list-item-left-qty-container'}>
                    <div className={'shopping-cart-right-product-list-item-left-qty-title'}>QTY</div>
                    <InputNumber
                      className={'shopping-cart-right-product-list-item-left-qty-input'}
                      min={1}
                      value={item.Quantity}
                      onStep={value => handleQuantityChange(value, item)}
                      onPressEnter={event => handleQuantityChange(event.target.value, item)}
                      onBlur={event => handleQuantityChange(event.target.value, item)}
                    />
                  </div>
                </div>
                <div className={'shopping-cart-right-product-list-item-right-container'}>
                  <div className={'shopping-cart-right-product-list-item-right-info-container'}>
                    <div className={'shopping-cart-right-product-list-item-right-info-title'}>
                      {item.Name}
                    </div>
                    <div className={'shopping-cart-right-product-list-item-right-info-price'}>
                      ${getFormatMoney(item.UnitPrice)}
                      <span>&nbsp;/ Per-Pound</span>
                    </div>
                    <div className={'shopping-cart-right-product-list-item-right-info-size'}>
                      Size: {item.PackageSize}
                    </div>
                  </div>
                  <div
                    className={'shopping-cart-right-product-list-item-right-btn'}
                    onClick={() => handleRemoveItemClick(item)}>
                    Remove item
                  </div>
                </div>
              </div>)
            }
          </div>
          <div className={'shopping-cart-right-line'}></div>
          <div className={'shopping-cart-right-order-info-container'}>
            <div className={'shopping-cart-right-order-info-item-container'}>
              <div className={'shopping-cart-right-order-info-item-title'}>Subtotal</div>
              <div className={'shopping-cart-right-order-info-item-val'}>${getFormatMoney(cartInfo?.TotalPrice)}</div>
            </div>
            <div className={'shopping-cart-right-order-info-item-container'}>
              <div className={'shopping-cart-right-order-info-item-title'}>Tax</div>
              <div className={'shopping-cart-right-order-info-item-val'}>${getFormatMoney(cartInfo?.Tax)}</div>
            </div>
            <div className={'shopping-cart-right-order-info-item-container'}>
              <div className={'shopping-cart-right-order-info-item-title'}>Promotion</div>
              <div className={'shopping-cart-right-order-info-item-val'}>${getFormatMoney(cartInfo?.Savings)}</div>
            </div>
            <div className={'shopping-cart-right-order-info-item-container'}>
              <div className={'shopping-cart-right-order-info-item-title'}>Shipping</div>
              <div className={'shopping-cart-right-order-info-item-val'}>${getFormatMoney(cartInfo?.Delivery)}</div>
            </div>
            <div className={'shopping-cart-right-line shopping-cart-right-order-info-line'}></div>
            <div className={'shopping-cart-right-order-info-item-container'}>
              <div className={'shopping-cart-right-order-info-item-title'}>Total</div>
              <div className={'shopping-cart-right-order-info-item-val'}>${getFormatMoney(cartInfo?.TotalPrice)}</div>
            </div>
          </div>
          <div className={'shopping-cart-right-btn'} onClick={handlePlaceAnOrderClick}>
            Place An order
          </div>
        </div>
      </div>
    </Layout>
  )
}
