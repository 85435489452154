import React from 'react'
import {useSelector} from 'react-redux';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductItem from '@/components/ProductItem'
import bannerImg from '@/assets/img/newDetail/banner.jpg';
import product2Img from '@/assets/img/home/product2.png';

export default function NewDetail() {
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'new-detail-mobile-container' : 'new-detail-container'

  const contentList = [
    {
      title: 'Introduction',
      des: 'Welcome to "From Sea to Plate," a culinary adventure that takes you on a fascinating journey into the world of fresh seafood. Join us as we embark on a captivating exploration of oceanic delights, from the depths of the sea to the delectable dishes on your plate. In this news, we\'ll dive into the intricacies of seafood sourcing, share mouthwatering recipes, uncover sustainable practices, and showcase the rich traditions and stories that surround this incredible culinary realm. Prepare to indulge your senses and expand your knowledge as we delve into the diverse and enticing world of fresh seafood.'
    },
    {
      title: 'Section 1',
      des: 'The Oceanic Bounty In this section, we\'ll marvel at the vast array of seafood available to us. From succulent fish and tender shellfish to exotic crustaceans, we\'ll explore the different types of seafood that grace our plates. Dive into the depths of knowledge about various fish species, their unique characteristics, and the regions they inhabit. Learn about the seasonal availability of seafood and how it influences our culinary choices.'
    },
    {
      title: 'Section 2',
      des: 'Sustainable Sourcing and Responsible Consumption As we savor the wonders of seafood, it\'s crucial to understand the importance of sustainable sourcing. This section will shed light on sustainable fishing practices, aquaculture, and the impact of our choices on marine ecosystems. Discover how to make informed decisions when purchasing seafood, opting for certified sustainable options, and supporting responsible fishing communities.'
    },
    {
      title: 'Section 3',
      des: 'From Ocean to Kitchen: The Art of Seafood Preparation Preparing seafood can be both a science and an art. In this section, we\'ll explore the best techniques for cleaning, filleting, and preparing different types of seafood. From searing and grilling to poaching and baking, we\'ll delve into the cooking methods that enhance the natural flavors of seafood. Discover the perfect seasoning combinations, marinades, and sauces that elevate your seafood dishes to new heights.'
    },
    {
      title: 'Section 4',
      des: 'Unleashing Creativity: Inspiring Seafood Recipes Get ready to tantalize your taste buds with a collection of inspiring seafood recipes. From classic dishes to innovative creations, we\'ll share step-by-step instructions, cooking tips, and flavor pairings that bring out the best in each ingredient. Explore recipes for seafood pastas, grilled delights, seafood stews, and more. Whether you\'re a seafood aficionado or a beginner, there\'s something for everyone to try and enjoy.'
    },
    {
      title: 'Section 5',
      des: 'Coastal Traditions and Stories Seafood is not just about the food; it\'s deeply intertwined with cultural traditions and stories. In this section, we\'ll journey to coastal communities and uncover the rich heritage and customs associated with seafood. Explore the tales of generations of fishermen, seafood festivals, and the rituals that have shaped these communities. Immerse yourself in the vibrant tapestry of coastal life, where seafood is more than just a meal—it\'s a way of life.'
    },
    {
      title: 'Conclusion',
      des: 'As we conclude our exploration of the world of fresh seafood, we hope you\'ve been inspired to embark on your own culinary adventures. From learning about sustainable sourcing to trying new recipes and embracing coastal traditions, the journey from sea to plate is one filled with discoveries and delights. Join us as we continue to delve into the depths of this captivating realm, uncovering the treasures of the ocean and savoring the exquisite flavors that it offers. Let "From Sea to Plate" be your guide as you embark on an extraordinary culinary odyssey.'
    },
  ]
  const productList = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]
  
  const renderNewContentItem = (item, index) => <div 
    className={'new-detail-content-item-container'}
    key={`new_detail_content_item_${index}`}>
    <div className={'new-detail-content-item-title'}>{item.title}</div>
    <div className={'new-detail-content-item-des'}>{item.des}</div>
  </div>
  const renderNewDetailContent = () => <div className={'new-detail-content-container'}>
    {contentList.map((item, index) => renderNewContentItem(item, index))}
  </div>
  
  const renderProductListItem = (item, index) => <div
    className={'new-detail-related-blogs-item-container'}
    key={`product_list_item_${index}`}>
    <ProductItem
      img={product2Img}
      price={''}
      des={'From Sea to Plate: Exploring the World of Fresh Seafood'}
      btnText={'Read More'}
    />
  </div>
  const renderProductList = () => <div className={'new-detail-related-blogs-container'}>
    <div className={'new-detail-related-blogs-title'}>Related news</div>
    <div className={'new-detail-related-blogs-list'}>
      {productList.map((item, index) => renderProductListItem(item, index))}
    </div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'new-detail-header-container'}
          des={'NEWS'}
          title={'From Sea to Plate: Exploring the World of Fresh Seafood'}
          banner={bannerImg}
        />
        {renderNewDetailContent()}
        {renderProductList()}
      </div>
    </Layout>
  )
}
