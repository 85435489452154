import instance from '../http-client'
import {
  HTTP_DELETE,
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT
} from '@/constants'
import {
  getLocationInfo
} from '@/common';

export const API_URL = `/api`;
export const API_V1_URL = `${API_URL}/v1`;
export const MOBILE_URL = `${API_V1_URL}/mobile`;
export const getCategory2 = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/category2/`,
    params: {
      ...locationInfo,
      IsListView: true,
      nested: true,
    }
  })
}
export const getBillboard = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/billboard/`,
    params: {
      ...locationInfo,
      Mobile: true,
    }
  })
}

export const addToCart = function (item, quantity) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/order-cart`,
    data: {
      Product_id: item.Product_id,
      Quantity: quantity || 1,
    },
    params: {
      ...locationInfo,
    }
  })
}
export const updateItemQuantity = function (item, quantity) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/order-cart`,
    data: {
      Product_id: item.Product_id,
      Quantity: quantity,
    },
    params: {
      ...locationInfo,
    }
  })
}
export const deleteCartItem = function (item) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_DELETE,
    url: `${MOBILE_URL}/order-cart`,
    data: {
      Product_id: item.Product_id,
    },
    params: {
      ...locationInfo,
    }
  })
}
export const getOrderCart = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/order-cart`,
    params: {
      ...locationInfo,
    }
  })
}
export const getCart = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/cart/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const submitOrder = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/order/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getApiTokenAuth = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_URL}/api-token-auth/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getCustomerOao = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/customer/oao/`,
    params: {
      ...locationInfo,
    }
  })
}
export const getProductDetail = function (num) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/product/${num}`,
    params: {
      ...locationInfo,
    }
  })
}

export const getMoreProducts = function (categoryId, limit = 8) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/${categoryId}`,
    params: {
      ...locationInfo,
      limit
    },
  })
}

export const getProductGroup = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/`,
    params: {
      ...locationInfo,
    }
  })
}
export const getGroupProducts = function (groupId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/${groupId}`,
    params: {
      ...locationInfo,
    }
  })
}
export const getCategoryProducts = function (categoryId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/category/${categoryId}`,
    params: {
      ...locationInfo,
    }
  })
}
