import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Form, Input, Select} from 'antd'

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';


const {Option} = Select;

const defaultProps = {
  title: '',
  className: '',
  onRegisterAccountSuccess: () => {
  },
};

const RegisterAccount = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'register-account-mobile-container' : 'register-account-container'

  const formRef = useRef(null);

  const businessTypeList = [
    {
      label: 'Individual',
      value: 'Individual',
    },
    {
      label: 'Retailer',
      value: 'Retailer',
    },
    {
      label: 'Restaurant',
      value: 'Restaurant',
    },
    {
      label: 'Manufacturer',
      value: 'Manufacturer',
    },
    {
      label: 'Wholesaler / Distributor',
      value: 'Wholesaler / Distributor',
    },
    {
      label: 'Others',
      value: 'Others',
    },
  ]

  const onFormFinish = (values) => {
    options.onRegisterAccountSuccess(values)
  }
  const resetFields = () => formRef.current?.resetFields()

  const handleApplyNowClick = () => {
    formRef.current?.submit()
  }

  const handleTermsOfServiceClick = () => {
    navigate('/termsOfService')
  }
  const handlePrivacyPolicyClick = () => {
    navigate('/privacyPolicy')
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  useImperativeHandle(ref, () => ({
    resetFields,
  }))

  return (
    <div className={className}>
      {
        options.title &&
        <div className={'register-account-title'}>
          {options.title}
        </div>
      }
      <Form
        className={'register-account-form-container'}
        ref={formRef}
        layout={'vertical'}
        requiredMark={false}
        onFinish={onFormFinish}
        autoComplete="off">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}>
          <Input
            placeholder={'Enter your name'}
          />
        </Form.Item>
        <Form.Item
          label="Email Address"
          name="emailAddress"
          rules={[
            {
              required: true,
              message: 'Please enter your email address',
            },
          ]}>
          <Input
            placeholder={'Enter your email address'}
          />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="companyPhone">
          <Input
            placeholder={'Enter your phone number'}
          />
        </Form.Item>
        <Form.Item
          label="Company"
          name="company"
          rules={[
            {
              required: true,
              message: 'Please enter your company name',
            },
          ]}>
          <Input
            placeholder={'Enter your company name'}
          />
        </Form.Item>
        <Form.Item
          label="Company Phone"
          name="companyPhone">
          <Input
            placeholder={'Enter your company phone'}
          />
        </Form.Item>
        <Form.Item
          label="Business Type"
          name="businessType"
          id={'register-account-monthly-volume-root'}>
          <Select
            placeholder="Enter your company business type"
            getPopupContainer={() => document.getElementById('register-account-monthly-volume-root')}>
            {
              businessTypeList.map((item, index) => {
                return <Option
                  value={item.value}
                  key={'business_type_' + index}>
                  {item.label}
                </Option>
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="EIN Number"
          name="einNumber">
          <Input
            placeholder={'Enter your company EIN number'}
          />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: 'Please enter your address',
            },
          ]}>
          <Input
            placeholder={'Enter your address'}
          />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              message: 'Please enter your address city',
            },
          ]}>
          <Input
            placeholder={'Enter your address city'}
          />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[
            {
              required: true,
              message: 'Please enter your address state',
            },
          ]}>
          <Input
            placeholder={'Enter your address state'}
          />
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="zipCode"
          rules={[
            {
              required: true,
              message: 'Please enter your address zipcode',
            },
          ]}>
          <Input
            placeholder={'Enter your address zipcode'}
          />
        </Form.Item>
      </Form>
      <div className={'register-account-tip-container'}>
        <span>By continuing, you agree to Galaxy Global International’s </span>
        <span className={'register-account-tip-link'} onClick={handleTermsOfServiceClick}>Terms of Service</span>
        <span> and </span>
        <span className={'register-account-tip-link'} onClick={handlePrivacyPolicyClick}>Privacy Policy</span>
      </div>
      <div className={'register-account-btn'} onClick={handleApplyNowClick}>Apply Now</div>
    </div>
  )
})

export default RegisterAccount
