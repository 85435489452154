import React from 'react'
import {useSelector} from 'react-redux';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';
import GSwiper from '@/components/GSwiper';

const defaultProps = {
  des: '',
  title: '',
  banner: '',
  className: '',
  btnText: '',
  onBtnClick: () => {},
  bannerList: []
};
export default function ContentHeader(props) {
  const options = Object.assign({}, defaultProps, props);

  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'content-header-mobile-container' : 'content-header-container'
  
  const {bannerList} = options
  const isShowBannerList = !!bannerList?.length

  const usingRootClassName = getUsingClassName(
    [rootClassName],
    [options.className]
  );
  const renderSwiperSlideContent = (item, index) => <div 
    className={'content-header-banner-list-item-container'}>
    <img src={item.img} alt="" />
  </div>
  
  return (
    <div className={usingRootClassName}>
      {
        options.des && <div className={'content-header-des'}>
          {options.des}
        </div>
      }
      {
        options.title && <div className={'content-header-title'}>
          {options.title}
        </div>
      }
      {
        options.banner && <div className={'content-header-banner-container'}>
          <img src={options.banner} alt=""/>
        </div>
      }
      {
        isShowBannerList && <div
          className={'content-header-banner-list-container'}>
          <GSwiper
            className={'content-header-banner-list-swiper'}
            prevClassName={'content-header-banner-list-prev'}
            nextClassName={'content-header-banner-list-next'}
            data={bannerList}
            renderSwiperSlideContent={renderSwiperSlideContent}
          />
        </div>
      }
      {
        options.btnText && <div className={'content-header-btn'} onClick={options.onBtnClick}>
          {options.btnText}
        </div>
      }
    </div>
  )
}
