import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import './index.less'
import './mobile.less'

import logoImg from '@/assets/img/logo.png'
import wechatImg from '@/assets/img/icon/wechat.png'
import instagramImg from '@/assets/img/icon/instagram.png'
import facebookImg from '@/assets/img/icon/facebook.png'

const defaultProps = {
  isShowFooterTop: true,
};

export default function Footer(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'footer-mobile-container' : 'footer-container'
  
  const handleBlogClick = () => {
    const path = '/news'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  const handleRecipeClick = () => {
    const path = '/recipe'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  const handleCareerClick = () => {
    const path = '/career'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  
  return (
    <div className={rootClassName} id={'page-footer'}>
      {
        options.isShowFooterTop &&
        <div className={'footer-top-container'}>
          <div className={'footer-top-content-container'}>
            <div className={'footer-top-content-left-container'}>
              <div className={'footer-top-content-left-logo-container'}>
                <img src={logoImg} alt="logo" />
              </div>
              <div className={'footer-top-content-left-des'}>
                Galaxy Global International LLC is a distributor, importer, and processor of seafood. Our mission is to make the seafood business easier and to be your most reliable partner in the seafood industry.
              </div>
              <div className={'footer-top-content-link-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleBlogClick}>News</div>
                <div className={'footer-top-content-title footer-link'} onClick={handleRecipeClick}>recipe</div>
                <div className={'footer-top-content-title footer-link'} onClick={handleCareerClick}>career</div>
              </div>
              <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title'}>Get in touch</div>
                <div className={'footer-top-content-left-get-in-touch-list'}>
                  <div className={'footer-top-content-left-get-in-touch-item-container'}>
                    <img src={wechatImg} alt="wechat" />
                  </div>
                  <div className={'footer-top-content-left-get-in-touch-item-container'}>
                    <img src={instagramImg} alt="instagram" />
                  </div>
                  <div className={'footer-top-content-left-get-in-touch-item-container'}>
                    <img src={facebookImg} alt="facebook" />
                  </div>
                </div>
              </div>
            </div>
            <div className={'footer-top-content-right-container'}>
              <div className={'footer-top-content-right-working-hours-container'}>
                <div className={'footer-top-content-title'}>WORKING HOURS</div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  Monday - Saturday
                  <span>: 9am - 6pm</span>
                </div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  Sunday
                  <span>: 1pm - 6pm</span>
                </div>
              </div>
              <div className={'footer-top-content-right-contact-us-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleContactUsClick}>CONTACT Us</div>
                <div className={'footer-top-content-right-contact-us-title'}>
                  Galaxy Global International
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  4300 Pleasantdale Rd b, Suite B. Atlanta, GA 30340
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  Phone: (770)-676-9288
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  Fax: (770)-695-0145
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  Sales Direct line: (470)-535-7677
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  Email:&nbsp;
                  <span className={'footer-link'}>
                  op@ggifoods.com
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={'footer-bottom-container'}>
        © 2023 Galaxy Global International INC.   | Powered by TruckXi
      </div>
    </div>
  )
}
