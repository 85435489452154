import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import {useNavigate, useSearchParams} from 'react-router-dom';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import Login from '@/components/Login'
import RegisterAccount from '@/components/RegisterAccount'

import {getDecodeStr} from '@/common';
import {REDIRECT_KEY} from '@/constants';

export default function SignupLogin() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'signup-login-mobile-container' : 'signup-login-container'

  const [isLogin, setIsLogin] = useState(true);
  
  const handleRegisterAccountClick = () => {
    setIsLogin(false)
  }
  const handleLogInClick = () => {
    setIsLogin(true)
  }
  const handleLoginSuccess = () => {
    const enCodeRedirect = searchParams.get(REDIRECT_KEY)
    let url = '/'
    if (enCodeRedirect) {
      url = getDecodeStr(enCodeRedirect)
    }
    navigate(url, {replace: true})
  }
  
  return (
    <Layout>
      <div className={rootClassName}>
        {
          isLogin &&
          <Login
            onRegisterAccountClick={handleRegisterAccountClick}
            onLoginSuccess={handleLoginSuccess}
          />
        }
        {
          !isLogin &&
          <div className={'signup-login-register-account-container'}>
            <RegisterAccount
              title={'Start business with us'}
            />
            <div className={'signup-login-register-account-divider'}>Already have account?</div>
            <div className={'signup-login-register-account-btn'} onClick={handleLogInClick}>
              Log In
            </div>
          </div>
        }
      </div>
    </Layout>
  )
}
