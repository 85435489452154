import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductItem from '@/components/ProductItem'
import bannerImg from '@/assets/img/news/banner.jpg';
import product2Img from '@/assets/img/home/product2.png';

export default function News() {
  const navigate = useNavigate()
  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'news-mobile-container' : 'news-container'

  const productList = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  const handleProductItemClick = () => {
    const path = '/newDetail'
    if (location.pathname === path) {
      return
    }
    navigate(path);
  }
  
  const renderProductListItem = (item, index) => <div
    className={'news-content-item-container'}
    key={`product_list_item_${index}`}>
    <ProductItem
      img={product2Img}
      price={''}
      des={'From Sea to Plate: Exploring the World of Fresh Seafood'}
      btnText={'Read More'}
      onProductItemClick={handleProductItemClick}
    />
  </div>
  const renderProductList = () => <div className={'news-content-container'}>
    <div className={'news-content-list'}>
      {productList.map((item, index) => renderProductListItem(item, index))}
    </div>
    <div className={'news-content-btn'}>Click to show more</div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'news-header-container'}
          des={'Exploring the news, One Blog at a Time'}
          title={'Join us on this journey of discovery and let our blog be your source of inspiration and insight.'}
          banner={bannerImg}
        />
        {renderProductList()}
      </div>
    </Layout>
  )
}
