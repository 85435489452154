import React, {useMemo} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import {isFunction} from 'lodash';

import {LeftOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import logoImg from '@/assets/img/logo.png'

import {changeLanguage, logout} from '@/store/modules/common.slice';

import {getUsingClassName} from '@/common';

const LANGUAGE_KEY = 'language'
const LOGOUT_KEY = 'Logout'
const productRootPath = '/ourProducts'
const allUserMenuList = [
  {
    label: 'Product',
    path: productRootPath
  },
  {
    label: 'Recipe',
    path: '/recipe'
  },
]
const guestMenuList = [
  ...allUserMenuList,
  {
    label: 'sign up/log in',
    path: '/signupLogin'
  },
  {
    label: '中文 / EN',
    path: LANGUAGE_KEY
  },
]
const loggedMenuList = [
  ...allUserMenuList,
  // {
  //   label: 'My profile',
  //   path: '/myProfile'
  // },
  {
    label: 'My Cart',
    path: '/shoppingCart'
  },
  {
    label: 'Log out',
    path: LOGOUT_KEY
  },
  {
    label: '中文 / EN',
    path: LANGUAGE_KEY
  },
]
const detailPageMenuList = [
  {
    label: 'Log out',
    path: LOGOUT_KEY
  },
  {
    label: '中文 / EN',
    path: LANGUAGE_KEY
  },
]

const defaultProps = {
  isDetailPage: false,
  detailPageTitle: '',
  onLeftDetailPageClick: undefined,
  className: ''
};

export default function Header(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const commonState = useSelector(state => state.common)
  const zipcode = commonState.zipcode
  const isLogged = commonState.isLogged
  const rootClassName = commonState.isMobile ? 'header-mobile-container' : 'header-container'

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  const useMenuList = useMemo(
    () => {
      if (!isLogged) {
        return guestMenuList
      }
      return options.isDetailPage ? detailPageMenuList : loggedMenuList
    }, 
    [isLogged]
  )

  const handleLeftDetailPageClick = () => {
    if (isFunction(options.onLeftDetailPageClick)) {
      return options.onLeftDetailPageClick()
    }
    navigate(-1)
  }
  
  const handleLogoutClick = () => {
    dispatch(logout())
    navigate('/home')
  }
  const handleLanguageChange = () => dispatch(changeLanguage())
  const handleLogoClick = () => navigate('/home')
  const handleMenuItemClick = (item, index) => {
    const {path} = item
    switch (path) {
      case LOGOUT_KEY:
        handleLogoutClick()
        break;
      case LANGUAGE_KEY:
        handleLanguageChange()
        break;
      default:
        path !== location.pathname && navigate(path)
    }
  }
  
  const renderZipcode = () => <div className={'header-right-zipcode-container'}>
    <div className={'header-right-zipcode-title'}>zipcode</div>
    <div className={'header-right-zipcode-val'}>{zipcode}</div>
  </div>
  const renderMenuItem = (item, index) => {
    let isAdd = item.path === location.pathname
    if (item.path === productRootPath && location.pathname.includes(productRootPath)) {
      isAdd = true
    }
    const className = getUsingClassName(
      ['header-right-menu-item-container'],
      ['header-right-menu-item-active-container'],
      isAdd
    )
    
    return <div
      className={className}
      onClick={() => handleMenuItemClick(item, index)}
      key={'menu_item_' + index}>
      {item.label}
    </div>
  }
  const renderMenu = () => useMenuList.map((item, index) => renderMenuItem(item, index))

  return (
    <div className={className}>
      {
        options.isDetailPage && <div
          className={'header-left-detail-page-container'} onClick={handleLeftDetailPageClick}>
          <LeftOutlined className={'header-left-detail-page-icon'} />
          <div className={'header-left-detail-page-title'}>{options.detailPageTitle}</div>
        </div>
      }
      {
        !options.isDetailPage &&
        <div
          className={'header-left-container'}
          onClick={handleLogoClick}>
          <img src={logoImg} alt="logo"/>
        </div>
      }
      <div className={'header-right-container'}>
        {
          !options.isDetailPage &&
          renderZipcode()
        }
        {renderMenu()}
      </div>
    </div>
  )
}
