import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {InputNumber, message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ProductItem from '@/components/ProductItem'
import GSwiper from '@/components/GSwiper'

import noImage from '@/assets/img/icon/noImage.png'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {addToCart, getMoreProducts, getProductDetail} from "@/api/req-api";
import {take} from "lodash";
import {beforeAddToCart, getFormatMoney} from '@/common';

export default function ProductDetail() {
  const {id} = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const commonState = useSelector(state => state.common)
  const {productCategoryMap, isLogged} = commonState
  const rootClassName = commonState.isMobile ? 'product-detail-mobile-container' : 'product-detail-container'
  const [productDetail, setProductDetail] = useState({})
  const [moreProducts, setMoreProducts] = useState([])
  const [quantity, setQuantity] = useState(1);
  useEffect(() => {
    if (id) {
      fetchDetail(id)
    }
  }, [id])
  const fetchDetail = async (id) => {
    try {
      const {data: [productDetailResponse]} = await getProductDetail(id)
      setProductDetail(productDetailResponse)
      const {data: moreProductResponse} = await getMoreProducts(productDetailResponse.ProductGroup_id)
      setMoreProducts(take(moreProductResponse, 8))
    } catch (e) {
      //todo show error 
    }
  }

  const renderProductImgSwiperSlideContent = (item, index) => {
    return <div
      className={'product-detail-top-info-left-img-container'}
      key={`product_detail_img_${index}`}>
      <img src={item} alt=""/>
    </div>
  }
  const renderProductImgSwiper = () => <GSwiper
    className={'product-detail-top-info-left-product-img-swiper-container'}
    swiperClassName={'product-detail-top-info-left-product-img-swiper-swiper'}
    prevClassName={'product-detail-top-info-left-product-img-swiper-prev'}
    nextClassName={'product-detail-top-info-left-product-img-swiper-next'}
    data={productDetail?.Images && productDetail.Images.length > 0 ? productDetail.Images : [noImage]}
    renderSwiperSlideContent={renderProductImgSwiperSlideContent}
    isShowPagination={true}
    isShowNavigation={false}
  />

  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem isShowPrice={isLogged} />
  }
  const renderProductList = () => <GSwiper
    className={'product-detail-top-product-list-container'}
    data={[
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ]}
    renderSwiperSlideContent={renderSwiperSlideContent}
    slidesPerView={4}
  />
  
  const handleAddToCart = item => {
    addToCart(item, quantity).then(() => {
      message.success('Added successfully')
    })
  }
  const handleBeforeAddToCart = item => {
    beforeAddToCart(
      navigate,
      commonState.isLogged,
      location,
      () => handleAddToCart(item)
    )
  }
  
  const handleProductClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    // if (location.pathname === path) {
    //   return
    // }
    navigate(path);
  }
  
  const renderProductGroupItem = (item, index) => <div
    className={'product-detail-more-product-item-container'}
    key={`product_group_2_item_${index}`}>
    <ProductItem 
        img={item.Thumbnail} 
        price={item.Price} 
        des={item.Name} 
        isShowPrice={isLogged}
        isFormatPriceToMoney={true}
        onBtnClick={() => handleBeforeAddToCart(item)}
        onProductItemClick={() => handleProductClick(item)}
    />
  </div>
  const renderRichTextDescription = () => {
    if (!!productDetail?.rich_text_description) {
      return <div className={'product-detail-rich-text-description-container'}>
        <div className={'product-detail-rich-text-description-title'}>Description</div>
        <div className="product-detail-rich-text-description-content" dangerouslySetInnerHTML={{__html: productDetail?.rich_text_description}}/>
      </div>
    }
  }
  const renderMoreProduct = () => <div className={'product-detail-more-product-container'}>
    <div className={'product-detail-more-product-title'}>MORE PRODUCT</div>
    <div className={'product-detail-more-product-list'}>
      {moreProducts.map((item, index) => renderProductGroupItem(item, index))}
    </div>
  </div>
  return (
    <Layout>
      <div className={rootClassName}>
        <div className={'product-detail-top-container'}>
          <div className={'product-detail-top-title'}>
            <span>{productDetail?.ProductCategory1_id ? productCategoryMap[productDetail.ProductCategory1_id]?.Name : ''}</span>
            <div>{productDetail?.Name}</div>
          </div>
          <div className={'product-detail-top-info-container'}>
            <div className={'product-detail-top-info-left-container'}>
              {renderProductImgSwiper()}
            </div>
            <div className={'product-detail-top-info-right-container'}>
              <div className={'product-detail-top-info-right-ref-no'}>Ref No#: {productDetail?.RefNum}</div>
              {
                isLogged && <div className={'product-detail-top-info-right-price'}>
                  {`$${getFormatMoney(productDetail?.Price) ?? ''}`}
                  {productDetail?.ProductType === 'Non-Fixed' ? <span>&nbsp;/ ${productDetail?.MeasureUnit}</span> : ''}
                  {productDetail?.ProductType === 'Per-Pound' ? <span>&nbsp;/ Per-Pound</span> : ''}
                </div>
              }
              
              <div className={'product-detail-top-info-right-product-description-container'}>
                <div className={'product-detail-top-info-right-product-description-title'}>
                  Product Description
                </div>
                <div className={'product-detail-top-info-right-product-description-item'}>
                  {productDetail?.Description}
                </div>
              </div>
              <div className={'product-detail-top-info-right-upcs'}>
                UPCs:&nbsp;
                <span>Yes</span>
              </div>
              <div className={'product-detail-top-info-right-bottom-container'}>
                <div className={'product-detail-top-info-right-bottom-left-container'}>
                  <div className={'product-detail-top-info-right-bottom-left-label'}>QTY</div>
                  <InputNumber
                    className={'product-detail-top-info-right-bottom-left-input'}
                    min={1}
                    defaultValue={quantity}
                    onChange={setQuantity}
                  />
                </div>
                <div className={'product-detail-top-info-right-bottom-btn'} onClick={() => handleBeforeAddToCart(productDetail)}>Add to cart</div>
              </div>
            </div>
          </div>
          {renderProductList()}
        </div>
        {renderRichTextDescription()}
        {renderMoreProduct()}
      </div>
    </Layout>
  )
}
