import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {get} from 'lodash'

import {getIsMobile, removeToken, removeUserZipcode, setToken, setUserZipcode} from '@/common';
import {BILLING_ADDRESS_KEY, DEFAULT_ZIPCODE, EN_LANGUAGE_KEY, ZH_LANGUAGE_KEY} from '@/constants';
import {getApiTokenAuth, getCustomerOao, getProductGroup} from '@/api/req-api'

export const userLogin = createAsyncThunk(
  'common/login',
  async ({username, password}, {rejectWithValue}) => {
    try {
      const response = await getApiTokenAuth({
        username,
        password
      });
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const fetchUserInfo = createAsyncThunk(
  'common/userInfo',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getCustomerOao();
      return get(response, 'data');
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchProductGroup = createAsyncThunk(
    'common/productGroup',
    async (_, {rejectWithValue}) => {
      try {
        const response = await getProductGroup();
        return get(response, 'data');
      } catch (e) {
        return rejectWithValue(e);
      }
    },
)

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isMobile: getIsMobile(),
    locale: EN_LANGUAGE_KEY,
    loading: false,
    isLogged: false,
    userInfo: null,
    customerDetail: [],
    billingAddress: null,
    productGroupMap: {},
    productCategoryMap: {},
    productGroupMenu: [],
    zipcode: DEFAULT_ZIPCODE
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    setLocale(state, {payload}) {
      state.locale = payload;
    },
    changeLanguage(state) {
      state.locale = state.locale === EN_LANGUAGE_KEY ? ZH_LANGUAGE_KEY : EN_LANGUAGE_KEY
    },
    setIsLogged(state, {payload}) {
      state.isLogged = payload;
    },
    logout(state) {
      state.isLogged = false
      state.userInfo = null
      state.customerDetail = []
      state.billingAddress = null
      state.zipcode = DEFAULT_ZIPCODE
      removeUserZipcode()
      removeToken()
    }
  },
  extraReducers: builder => {
    builder.addCase(userLogin.fulfilled, (state, {payload}) => {
      state.isLogged = true
      setToken(payload.token)
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, {payload}) => {
      state.userInfo = payload
      const customerDetail = payload?.Customer_Detail || []
      state.customerDetail = customerDetail
      const billingAddress = customerDetail.find(item => item.Type === BILLING_ADDRESS_KEY)
      state.billingAddress = billingAddress
      const zipcode = billingAddress?.Zip || DEFAULT_ZIPCODE
      state.zipcode = zipcode
      setUserZipcode(zipcode)
    })
    builder.addCase(fetchProductGroup.fulfilled, (state, {payload}) => {
      const group = {}, category = {}, menu = []
      for (let i = 0; i < (payload || []).length; i++) {
        const groupRow = payload[i]
        group[groupRow.ProductGroup_id] = groupRow
        const groupCategories = groupRow.Categories ?? []
        const childrenItem = [];
        for (let j = 0; j < groupCategories.length; j++) {
          const cateRow = groupCategories[j];
          category[cateRow.ProductCategory_id] = {...cateRow, ProductGroup_id: groupRow.ProductGroup_id}
          childrenItem.push({
            label: cateRow.Name,
            key: cateRow.Name,
          })
        }
        menu.push({
          label: groupRow.Name,
          key: groupRow.Name,
          children: childrenItem,
        })
      }
      state.productGroupMap = group
      state.productCategoryMap = category
      state.productGroupMenu = menu
    })
  },
});

export const {startLoading, stopLoading, setLocale, changeLanguage, setIsLogged, logout} =
  commonSlice.actions;

export default commonSlice.reducer;
