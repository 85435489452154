import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import ProductItem from '@/components/ProductItem'
import bannerImg from '@/assets/img/career/banner.jpg';
import productImg from '@/assets/img/career/product.png';

export default function Career() {
  const navigate = useNavigate()
  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'career-mobile-container' : 'career-container'

  const productList = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  const handleProductItemClick = () => {
    const path = '/careerDetail'
    if (location.pathname === path) {
      return
    }
    navigate(path);
  }
  
  const renderProductListItem = (item, index) => <div
    className={'career-content-item-container'}
    key={`product_list_item_${index}`}>
    <ProductItem
      img={productImg}
      price={''}
      des={'Software Engineer Needed: Join Our Innovative Tech Team and Shape the Future'}
      btnText={'Read More'}
      onProductItemClick={handleProductItemClick}
    />
  </div>
  const renderProductList = () => <div className={'career-content-container'}>
    <div className={'career-content-list'}>
      {productList.map((item, index) => renderProductListItem(item, index))}
    </div>
    <div className={'career-content-btn'}>Click to show more</div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'career-header-container'}
          des={'Empowering Excellence, Together'}
          title={'Dynamic Professional Seeking New Opportunities to Make a Meaningful Impact'}
          banner={bannerImg}
        />
        {renderProductList()}
      </div>
    </Layout>
  )
}
