import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {kebabCase} from 'lodash'
import {message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ContentHeader from '@/components/ContentHeader'
import GSwiper from '@/components/GSwiper'
import ProductItem from '@/components/ProductItem'

import banner2Img from '@/assets/img/home/banner2.png'
import product2Img from '@/assets/img/home/product2.png'

import {getUsingClassName, checkViewableLanguages, beforeAddToCart} from '@/common';
import {addToCart, getBillboard, getCategory2} from '@/api/req-api'

export default function Home() {
  const navigate = useNavigate()
  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const zipcode = commonState.zipcode
  const isLogged = commonState.isLogged
  const rootClassName = commonState.isMobile ? 'home-mobile-container' : 'home-container'
  
  const [category2, setCategory2] = useState([])
  const buttonCategoriesList = useMemo(
    () => category2.filter(item => item.IsButton),
    [category2]
  )
  const enBtnCategoriesList = useMemo(
    () => buttonCategoriesList.filter(item => checkViewableLanguages(item.ViewableLanguages, 'en')).splice(0, 2),
    [buttonCategoriesList]
  )
  const zhBtnCategoriesList = useMemo(
    () => buttonCategoriesList.filter(item => checkViewableLanguages(item.ViewableLanguages, 'zh')).splice(0, 2),
    [buttonCategoriesList]
  )
  const notBtnCategoriesList = useMemo(
    () => category2.filter(item => !item.IsButton).map(item => {
      return {
        ...item,
        Products: item.Products
      }
    }),
    [category2]
  )
  const firstNotBtnCategories = useMemo(
    () => notBtnCategoriesList[0] || {},
    [notBtnCategoriesList]
  )
  const firstNotBtnCategoriesProducts = useMemo(
    () => firstNotBtnCategories?.Products || [],
    [firstNotBtnCategories]
  )
  const notFirstNotBtnCategories = useMemo(
    () => notBtnCategoriesList.splice(1),
    [notBtnCategoriesList]
  )

  const [billboard, setBillboard] = useState([])

  const productGroup2 = [
    {},
    {},
    {},
    {},
  ]
  useEffect(() => {
    getCategory2().then(res => {
      setCategory2(res.data)
    })
  }, [zipcode])
  useEffect(() => {
    getBillboard().then(res => {
      const formatData = res.data.map(item => {
        return {
          ...item,
          img: item.Image
        }
      })
      setBillboard(formatData)
    })
  }, [])

  const handleProductItemClick = item => {
    const path = `/productDetail/${item.ProductNum}`
    navigate(path);
  }
  const handleProductItem2Click = () => {
    const path = '/newDetail'
    if (location.pathname === path) {
      return
    }
    navigate(path);
  }
  const handleProductItemBtnClick = item => {
    addToCart(item).then(() => {
      message.success('Added successfully')
    })
  }
  
  const handleBeforeAddToCart = item => {
    beforeAddToCart(
      navigate,
      isLogged,
      location,
      () => handleProductItemBtnClick(item)
    )
  }
  
  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem
      onProductItemClick={() => handleProductItemClick(item)}
      img={item.Thumbnail}
      des={item.Name}
      price={item.Price}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      onBtnClick={() => handleBeforeAddToCart(item)}
    />
  }
  const renderProductList = (productList) => <GSwiper
    data={productList} 
    renderSwiperSlideContent={renderSwiperSlideContent}
    slidesPerView={4}
  />
  const renderProductGroup = (productList, title) => {
    const className = getUsingClassName(
      ['home-product-group-container'],
      [`home-product-group-${kebabCase(title)}`]
    )
    return <div className={className} key={title}>
      <div className={'home-product-group-title'}>{title}</div>
      <div className={'home-product-group-list'}>
        {renderProductList(productList)}
      </div>
    </div>
  }
  
  const renderProductGroup2Item = (item, index) => <div
    className={'home-product-group2-item-container'}
    key={`product_group_2_item_${index}`}>
    <ProductItem
      img={product2Img}
      price={''}
      des={'From Sea to Plate: Exploring the World of Fresh Seafood'}
      btnText={'Read More'}
      onProductItemClick={handleProductItem2Click}
    />
  </div>
  const renderProductGroup2 = () => <div className={'home-product-group2-container'}>
    <div className={'home-product-group2-list'}>
      {productGroup2.map((item, index) => renderProductGroup2Item(item, index))}
    </div>
    <div className={'home-product-group2-btn'}>Click to show more</div>
  </div>
  
  return (
    <Layout>
      <div className={rootClassName}>
        <ContentHeader
          className={'home-header-container'}
          des={'Galaxy Global International INC.'}
          title={'Galaxy Global International LLC is a distributor, importer, and processor of seafood.'}
          bannerList={billboard}
        />
        {renderProductGroup(firstNotBtnCategoriesProducts, firstNotBtnCategories.Name)}
        <div className={'home-img-container'}>
          {
            enBtnCategoriesList.map((item, index) => <div
              className={'home-img-item-container'}
              key={`en_btn_categories_list_${index}`}>
              <img src={item.Image} alt=""/>
            </div>)
          }
        </div>
        {
          notFirstNotBtnCategories.map((item, index) => renderProductGroup(item.Products, item.Name))
        }
        <div className={'home-whats-news-container'}>
          <img src={banner2Img} alt=""/>
        </div>
        {renderProductGroup2()}
      </div>
    </Layout>
  )
}
