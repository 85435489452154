import React, {useEffect} from 'react'
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import './index.less'
import './mobile.less'

import Header from '@/components/Headers'
import Footer from '@/components/Footers'

import {getUsingClassName} from '@/common';

const defaultProps = {
  className: '',
  isShowFooterTop: true,
  isDetailPage: false,
  detailPageTitle: '',
  onHeaderLeftDetailPageClick: undefined,
};

export default function Layout(props) {
  const options = Object.assign({}, defaultProps, props);

  const location = useLocation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'layout-mobile-container' : 'layout-container'

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }, [location.pathname])
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  
  return (
    <div className={className}>
      <Header
        className={'layout-header-container'}
        isDetailPage={options.isDetailPage}
        detailPageTitle={options.detailPageTitle}
        onLeftDetailPageClick={options.onHeaderLeftDetailPageClick}
      />
      <div className={'app-content-container'}>
        {options.children}
      </div>
      <Footer isShowFooterTop={options.isShowFooterTop} />
    </div>
  )
}
