import React from "react";
import './index.less'
const defaultProps = {
    text: 'Loading',
}
const Loading = props => {
    const option = Object.assign({}, defaultProps, props)
    return <div className="load-more disableBtn">
        <span>{option.text}</span>
        <i className="loading">
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
            <div className="loading-bar"></div>
        </i>
    </div>
}

export default Loading