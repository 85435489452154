import React from 'react'
import {useSelector} from 'react-redux';

import './index.less'
import './mobile.less'

import productImg from '@/assets/img/product.png'
import noImage from '@/assets/img/icon/noImage.png'
import {getFormatMoney} from '@/common';

const defaultProps = {
  img: productImg,
  price: '2.65',
  des: 'GS Tilapia IWP 250/350 1x40lb Old Grade C F CN',
  btnText: 'Add to cart',
  onProductItemClick: () => {},
  onBtnClick: () => {},
  productType: null,
  isShowPrice: true,
  isFormatPriceToMoney: false,
}
export default function ProductItem(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'product-item-mobile-container' : 'product-item-container'
  
  const {price, isShowPrice, isFormatPriceToMoney} = options
  const handleProductItemClick = () => {
    options.onProductItemClick()
  }
  const handleBtnClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
    options.onBtnClick()
  }
  
  return (
    <div className={rootClassName} onClick={handleProductItemClick}>
      <div className={'product-item-img-container'}>
          {options.img ? <img src={options.img} alt=""/> : <img src={noImage} alt=""/>}
      </div>
      {
        isShowPrice &&
        <div className={'product-item-price overflow-ellipsis-1'}>
          <span>${isFormatPriceToMoney ? getFormatMoney(price) : price}</span>
          {options.productType === 'Per-Pound' && <>&nbsp;/ Per-Pound</>}
        </div>
      }
      <div className={'product-item-des overflow-ellipsis-2'}>
        {options.des}
      </div>
      <div className={'product-item-btn'} onClick={handleBtnClick}>
        {options.btnText}
      </div>
    </div>
  )
}
