import React, {forwardRef, useImperativeHandle, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Form, Input} from 'antd';

import './index.less'
import './mobile.less'

import {getUsingClassName} from '@/common';

import {fetchUserInfo, userLogin} from '@/store/modules/common.slice';

const defaultProps = {
  className: '',
  onRegisterAccountClick: () => {},
  onLoginSuccess: () => {},
};

const RegisterAccount = forwardRef(function (props, ref) {
  const options = Object.assign({}, defaultProps, props);

  const dispatch = useDispatch()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'login-mobile-container' : 'login-container'

  const formRef = useRef(null);

  const onFormFinish = (values) => {
    const params = {
      password: values.password,
      username: values.name,
    }
    dispatch(userLogin(params))
      .then(({meta}) => {
        if (meta.requestStatus === 'rejected') {
          return
        }
        dispatch(fetchUserInfo())
        options.onLoginSuccess()
      })
  }
  const resetFields = () => formRef.current?.resetFields()

  const handleLogInClick = () => {
    formRef.current?.submit()
  }

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  useImperativeHandle(ref, () => ({
    resetFields,
  }))
  
  return (
    <div className={className}>
      <div className={'login-title'}><span>Welcome to</span> Galaxy Global International INC. </div>
      <div className={'login-content-container'}>
        <Form
          className={'login-form-container'}
          ref={formRef}
          layout={'vertical'}
          requiredMark={false}
          onFinish={onFormFinish}
          autoComplete="off">
          <Form.Item
            label="Email address or Phone Number"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter your email address or phone Number',
              },
            ]}>
            <Input
              placeholder={'Enter your email address or phone Number'}
            />
          </Form.Item>
          <div className={'login-form-password-container'}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your password',
                },
              ]}>
              <Input
                placeholder={'Enter your password'}
              />
            </Form.Item>
            <div className={'login-form-show-password'}>Show Password</div>
            <div className={'login-form-forget-password'}>Forget Password</div>
          </div>
        </Form>
        <div className={'login-btn'} onClick={handleLogInClick}>
          Log In
        </div>
        <div className={'login-divider'}>New to our store</div>
        <div className={'login-btn2'} onClick={options.onRegisterAccountClick}>
          Register Account
        </div>
      </div>
    </div>
  )
})

export default RegisterAccount
